<template>
  <b-row class="mb-2">
    <b-col
      md="5"
      class="d-flex justify-content-between"
    >
      <div>
        <slot name="label">
          <b>{{ label }}</b>
        </slot>
      </div>
      <div class="d-none d-md-flex">
        :
      </div>
    </b-col>
    <b-col md="7">
      <slot name="value">
        {{ value }}
      </slot>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      type: [String, Number],
      default: '-'
    },
  },
}
</script>