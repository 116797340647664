<template>
  <b-row
    class="mb-2"
    :class="disabled ? 'form-disabled' : ''"
  >
    <b-col
      md="5"
      class="d-flex justify-content-between"
    >
      <div class="mt-1">
        <slot name="label">
          <b>{{ label }}</b>
        </slot>
        <span
          v-if="required"
          class="ps-1"
          style="color: red"
        >*</span>
      </div>
      <div class="d-none d-md-flex mt-1">
        :
      </div>
    </b-col>
    <b-col md="7">
      <!-- Form Input -->
      <b-input-group 
        v-if="type != 'select'"
      >
        <b-form-input
          v-model="localValue"
          :type="type"
          :step="step"
          min="0"
          class="py-1"
          :class="`bg-${backgroundVariant}`"
          :placeholder="placeholder"
          :state="state"
          :disabled="disabled"
        />
        <b-input-group-append style="position:absolute; right: 0px">
          <div
            class="cancel-search-icon h-100 px-3"
            :class="{
              'd-flex': localValue != null && localValue != '',
              'd-none': localValue == null || localValue == '',
              'clear-button-number': type == 'number'
            }"
            style="position: relative;"
            @click="localValue = null"
          >
            <i class="ri-close-line my-auto fs-19" />
          </div>
        </b-input-group-append>
      </b-input-group>

      <!-- Form Select -->
      <Multiselect
        v-else
        ref="multiselect"
        v-model="localValue"
        class="py-1"
        :class="`bg-${backgroundVariant}`"
        :style="state === false ? 'border-color: #ef3451 !important' : ''"
        :placeholder="placeholder"
        :can-deselect="false"
        :close-on-select="true"
        :searchable="false"
        :create-option="false"
        :can-clear="canClear"
        :options="options"
        :state="state"
        :disabled="disabled"
        @click="toggleSelect"
        @close="isOpen = false"
      >
        <!-- Selected Option -->
        <template #singlelabel="{ value }">
          <div class="multiselect-single-label">
            <img
              v-if="hasIcon"
              class="me-2"
              :src="value.icon"
              style="max-height: 22px;"
            > 
            {{ value.label }}
          </div>
        </template>
        <!-- Caret  -->
        <template #caret>
          <span class="custom-caret">
            <i class="bx bx-chevron-down my-auto fs-4" />
          </span>
        </template>
        <!-- Available Options -->
        <template #option="{ option }">
          <img
            v-if="hasIcon && option.icon"
            :src="option.icon"
            class="me-2"
            style="max-height: 22px;"
          >
          <span class="ms-1">
            {{ option.label }}
          </span> 
        </template>
      </Multiselect>

      <!-- Error Feedback -->
      <b-form-invalid-feedback
        v-if="errors.length"
        class="d-inline"
      >
        <span
          v-for="(error, index) in errors"
          :key="index"
        >
          {{ error + ' ' }}
        </span>
      </b-form-invalid-feedback>
      <slot name="hint">
        <div
          v-if="hint"
          class="small mt-1 text-muted"
        >
          {{ hint }}
        </div>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"

export default {
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Label'
    },
    placeholder: {
      type: String,
      default: 'Enter'
    },
    backgroundVariant: {
      type: String,
      default: 'light'
    },
    hint: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    step: {
      type: Number,
      default: 1
    },
    canClear: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    state: {
      type: Boolean,
      default: null
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      isOpen: false,
      localValue: '',
    }
  },        
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.localValue = this.modelValue
      }
    },
    localValue(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  methods: {
    // Fix multiselect does not close on click like regular dropdown
    toggleSelect() {
      if(this.isOpen) {
        this.$refs.multiselect.close()
      } else {
        this.isOpen = true
        this.$refs.multiselect.open()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
input {
  border-radius: 0px;
  border-width: 0px;
  border-bottom: 1px solid #CED4DA;
}
.multiselect {
  border-radius: 0px;
  border-width: 0px;
  border-bottom: 1px solid #CED4DA;
}
.form-disabled {
  filter: opacity(0.7);
}
.clear-button-number {
  left: -22px;
	@media (max-width: 576px) {
    left: 0px
	}
}
</style>