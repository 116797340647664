<template>
  <b-modal
    id="fleet-model-modal"
    centered
    hide-footer
    hide-header
    title="Auto Fill Feature : Fleet Model"
    @shown="initModal"
  >
    <div class="modal-header flex-column mx-0">
      <div class="d-flex w-100">
        <h5 class="modal-title">
          Auto Fill Feature : Fleet Model
        </h5><button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div class="mt-2 me-auto">
        This feature will automatically fill in the fleet model section of the <b>Register Fleet Form.</b> <br>
        Any changes to this feature will not affect the previously saved fleet database in the system.
      </div>
    </div>
    <b-button
      variant="outline-info"
      class="w-100"
      @click="showForm = !showForm"
    >
      Add New Fleet Model
    </b-button>
    <b-form
      v-if="showForm"
      class="bg-light"
      @submit.prevent="submitForm"
    >
      <div style="border: 1px solid #CED4DA">
        <div
          class="d-flex p-1 bg-light"
          style="border-bottom: 1px solid #CED4DA;"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            class="bg-white"
            type="text"
            placeholder="Type model name"
          />
          <b-button
            type="submit"
            variant="success"
            class="d-flex ms-2"
          >
            <i class="fs-14 bx bx-save my-auto" />
          </b-button>
        </div>
        <div class="p-2">
          <FleetFormInput
            v-model="form.brand"
            background-variant="white"
            label="Brand"
            type="select"
            :can-clear="true"
            has-icon
            :options="fleetBrandOptions"
          />
          <FleetFormInput
            v-model="form.series"
            background-variant="white"
            label="Series"
          />
          <FleetFormInput
            v-model="form.fleet_type"
            background-variant="white"
            label="Fleet Type"
            type="select"
            :can-clear="true"
            :options="fleetTypeOptions"
          />
          <FleetFormInput
            v-model="form.cargo_type"
            background-variant="white"
            label="Cargo Type"
            type="select"
            :can-clear="true"
            :options="fleetCargoTypeOptions"
          />
          <FleetFormInput
            v-model="form.payload_capacity"
            background-variant="white"
            type="number"
          >
            <template #label>
              <b>Payload Capacity</b> (Daya Angkut) <b>- kg</b>
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.classification"
            background-variant="white"
            label="Classification"
            type="select"
            :can-clear="true"
            :options="fleetClassificationOptions"
          />
          <FleetFormInput
            v-model="form.engine_displacement"
            background-variant="white"
            type="number"
          >
            <template #label>
              <b>Engine Displacement</b> (Isi Silinder) <b>- cc</b>
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.fuel_capacity"
            background-variant="white"
            label="Fuel Tank Capacity - L"
            type="number"
          />
          <FleetFormInput
            v-model="form.fuel_type"
            background-variant="white"
            label="Fuel Type"
            type="select"
            :can-clear="true"
            :options="fleetFuelTypeOptions"
          />
          <FleetFormInput
            v-model="form.can_bus_available"
            background-variant="white"
            label="CAN Bus"
            type="select"
            :options="fleetCanBusOptions"
          />
          <!-- Fuel Ratio -->
          <FleetFormInput
            v-if="form.can_bus_available == 0"
            v-model="form.fuel_ratio"
            background-variant="white"
            label="Fuel Ratio - km/L"
            type="number"
            :step="0.01"
          />

          <FleetFormInput
            v-model="form.emission_coefficient"
            background-variant="white"
            label="Carbon Emission Coefficient - kgCO2/L"
            type="number"
            :step="0.01"
          />
        </div>
      </div>
    </b-form>
    <div
      v-for="(model, index) in fleetModels"
      :key="index"
    >
      <FleetModelItem
        :fleet-model="model"
        :reset="resetChild"
        @update="$emit('update')"
      />
    </div>
  </b-modal>
</template>

<script>
import { fleetCanBusOptions, fleetTypeOptions, fleetBrandOptions, fleetCargoTypeOptions, fleetClassificationOptions, fleetFuelTypeOptions } from '@/utils/constant';

import FleetFormInput from '@/components/fleet/FleetFormInput.vue';
import FleetModelItem from '@/components/fleet/FleetModelItem.vue';

import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
    FleetFormInput,
    FleetModelItem
  },
  props: {
    fleetModels: {
      type: Array,
      default() {
        return []
      }
    },
  },
  setup () {
    const toast = useToast();
    return {
      toast,
    }
  },
  data() {
    return {
      resetChild: 0,
      form: {
        name: '',
        brand: '',
        series: '',
        fleet_type: '',
        cargo_type: '',
        payload_capacity: '',
        classification: '',
        engine_displacement: '',
        fuel_capacity: '',
        fuel_type: '',
        can_bus_available: 0,
        fuel_ratio: '',
        emission_coefficient: 0,
      },
      showForm: false,
      errors: {},
      fleetCanBusOptions,
      fleetTypeOptions, 
      fleetBrandOptions,
      fleetCargoTypeOptions,
      fleetClassificationOptions,
      fleetFuelTypeOptions,
    };
  },
  computed: {
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
  },
  watch: {
    'form.fuel_type' : {
      immediate: false,
      handler(val) {
        if(val == 'diesel') {
          this.form.emission_coefficient = 2.70
        } else if(val == 'biodiesel') {
          this.form.emission_coefficient = 2.50
        } else if(val == 'motor gasoline') {
          this.form.emission_coefficient = 2.32
        }
      }
    }
  },
  methods: {
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
      this.form.can_bus_available = 0
      this.form.emission_coefficient = 0
    },
    async submitForm() {
      try {
        await this.$api.createOrganizationFleetModel(this.selectedOrganization, this.form);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Saved Successfully',
            icon: 'ri-check-double-line'
          }
        }
        this.toast(toastContent);
        this.resetForm();
        this.showForm = false;
        this.$emit('update')

      } catch (error) {
        console.log(error);
        this.errors = error.response.data.errors;
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
    initModal() {
      this.resetForm();
      this.showForm = false;
      this.resetChild += 1;
    },
    resetErrors() {
      this.errors = {}
    }
  },
};
</script>
<style lang="scss" scoped>
input {
  background-color: white;
}
</style>