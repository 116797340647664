<template>
  <b-modal
    id="delete-modal"
    v-model="showModal"
    size="sm"
    centered
    hide-footer
  >
    <template #title> 
      Delete {{ entity }} {{ parent ? 'in' : '' }} <u>{{ parent }}</u>
    </template>
    <div class="my-2">
      <b>
        {{ name }}
      </b>
    </div>
    <div>
      Are you sure to delete this {{ entity }}?
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div class="col-6 pe-2">
        <b-button
          variant="soft-primary"
          class="w-100"
          @click="deleteData"
        >
          Yes
        </b-button>
      </div>
      <div class="col-6 ps-2">
        <div class="d-flex">
          <b-button
            variant="primary"
            class="w-100"
            data-bs-dismiss="modal"
          >
            No
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    entity: {
      type: String,
      required: true,
      default: 'Item'
    },
    name: {
      type: String,
      required: true,
      default: ''
    },
    parent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    async deleteData() {
      this.$emit('delete-data');
        this.showModal = false;
    }
  },
};
</script>
