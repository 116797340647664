<template>
  <div
    class="small mt-1"
    :class="[maxLength ? (fieldLength > maxLength? 'text-danger' : 'text-muted') : 'text-muted']"
  >
    {{ fieldLength }} <span v-if="maxLength">of {{ maxLength }} characters</span>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    fieldLength() {
      if (this.field == null) return 0;
      return this.field.length;
    },
  },
};
</script>
