<!-- eslint-disable vue/no-unused-vars -->
<template>
  <DataTable
    :fields="tableFields"
    :items="fleets"
    :allow-local-sort="false"
    :is-loading="isLoading"
    :has-unit="true"
    @sort-changed="(val) => $emit('sort-changed', val)"
  >
    <template #cell(vehicle_number)="{ row }">
      <router-link
        :to="`/dashboard?fleet=${row.item.id}`"
        class="link-item-table custom-link"
        v-html="highlightString(row.item.vehicle_number, filterKeyword)"
      />
    </template>
    <template #cell(brand)="{ row }">
      <div
        class="d-flex text-center"
        style="white-space: nowrap;"
      >
        <img
          :src="getFleetBrandIcon(row.item.brand)"
          alt=""
          style="max-height: 20px; max-width: 28px;"
        >
        <span class="ps-2">
          {{ row.item.brand ?? '-' }}
        </span>
      </div>
    </template>
    <template #cell(cargo_type)="{ row }">
      <div class="text-capitalize">
        {{ row.item.cargo_type ?? '-' }}
      </div>
    </template>
    <template #cell(classification)="{ row }">
      {{ getClassificationLabel(row.item.classification) }}
    </template>
    <template #cell(fuel_type)="{ row }">
      <div class="text-capitalize">
        {{ row.item.fuel_type ?? '-' }}
      </div>
    </template>
    <template #cell(can_bus_available)="{ row }">
      <span
        class="my-auto text-capitalize px-2 flex-shrink-0"
        :style="row.item.can_bus_available ? '' : 'background-color: #D9D9D9'"
        style="border: 1px solid; border-radius: 20px; border-color: #909294;"
      >
        {{ row.item.can_bus_available ? 'Available' : 'Unavailable' }}
      </span>
    </template>
    <template #cell(fuel_ratio)="{ row }">
      {{ row.item.can_bus_available ? '' : row.item.fuel_ratio }}
    </template>
    <template #cell(device_info)="{ row }">
      <b-row class="justify-content-between flex-shrink-1">
        <b-col sm="7">
          <div>
            <b>ID:</b> {{ row.item.device_id || '-' }}
          </div>
          <div>
            <b>EUI:</b> {{ row.item.device_eui || '-' }}
          </div>
        </b-col>
        <b-col
          sm="5"
          class="d-flex justify-content-end"
        >
          <div
            class="d-flex my-auto text-center text-capitalize px-2 flex-shrink-0"
            :class="row.item.is_activated ? 'border-success' : 'border-danger'"
            style="border: 1px solid; border-radius: 20px;"
          >
            <i
              class="bx bxs-circle my-auto pe-1" 
              :class="row.item.is_activated ? 'text-success' : 'text-danger'"
            />
            {{ row.item.is_activated == 1 ? 'Activated' : row.item.is_activated === null ? 'Not Activated' : 'Activation Failed' }}
          </div>
        </b-col>
      </b-row>
    </template>
    <template #cell(last_tx_power_at)="{ row }">
      <div>
        {{ row.item.last_tx_power_at ? format(new Date(row.item.last_tx_power_at), 'dd MMM yyy HH:mm') : '-' }}
      </div>
    </template>
    <template #cell(latest_update)="{ row }">
      <div>
        {{ row.item.last_location?.created_at ? format(new Date(row.item.last_location.created_at), 'dd MMM yyy HH:mm') : '-' }}
      </div>
    </template>
    <template #cell(action)="{ row }">
      <div class="d-flex flex-shrink-0">
        <b-button
          v-if="userRole <= 1"
          variant="outline-info"
          class="me-2"
          data-bs-toggle="modal"
          href="#device-form-modal"
          @click="$emit('select-fleet', row.item)"
        >
          <i class="ri-edit-2-line" />
        </b-button>
        <b-button
          v-if="userRole <= 0"
          variant="outline-danger"
          data-bs-toggle="modal"
          href="#delete-modal"
          @click="$emit('select-fleet', row.item)"
        >
          <i class="ri-delete-bin-line" />
        </b-button>
      </div>
    </template>
  </DataTable>
</template>

<script>
  import DataTable from '@/components/DataTable.vue';
  import { format } from 'date-fns';
  import { fleetClassificationOptions, utcOffset } from '@/utils/constant';
  import { highlightString, getFleetBrandIcon } from '@/utils/helper';

  export default {
    components: {
      DataTable, 
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      fleets: {
        type: Array,
        default() {
          return []
        }
      },
      userRole: {
        type: Number,
        default: 4,
      },
      filterKeyword : {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        filter: {
          keyword: null,
          category: '',
          type: '',
          activity_type: '',
          per_page: 10,
          page: 1,
          sort_by: '',
          direction: ''
        },
      };
    },
    computed: {
      tableFields() {
        let tableFields = [{
            key: 'vehicle_number',
            label: 'Vehicle Number',
            unit: 'No. Polisi',
            sortable: true,
            class: 'fit',
          }, {
            key: 'driver_name',
            label: 'Driver',
            unit: 'Name',
            class: 'fit',
            thStyle: { minWidth: '12rem' },
          }, {
            key: 'vin',
            label: 'VIN',
            unit: 'No. Rangka',
          }, {
            key: 'engine_number',
            label: 'Engine Number',
            unit: 'No. Seri Mesin',
          }, {
            key: 'brand',
            label: 'Brand',
          }, {
            key: 'series',
            label: 'Series',
            thStyle: { minWidth: '4rem' },
          }, {
            key: 'fleet_type',
            label: 'Type',
            thStyle: { minWidth: '7rem' },
          }, {
            key: 'cargo_type',
            label: 'Cargo Type',
          }, {
            key: 'payload_capacity',
            label: 'Payload Capacity',
            unit: 'Daya Angkut (kg)',
          }, {
            key: 'classification',
            label: 'Classification',
          }, {
            key: 'engine_displacement',
            label: 'Engine Displacement',
            unit: 'Isi Silinder (cc)',
          }, {
            key: 'fuel_capacity',
            label: 'Fuel Tank',
            unit: 'Capacity (L)'
          }, {
            key: 'fuel_type',
            label: 'Fuel Type',
          }, {
            key: 'can_bus_available',
            label: 'CAN Bus',
          }, {
            key: 'fuel_ratio',
            label: 'Fuel Ratio',
            unit: 'km/L',
          }, {
            key: 'emission_coefficient',
            label: 'Carbon Emission',
            unit: 'Coefficient (kgCO2/L)',
          }, {
            key: 'device_info',
            label: 'Device',
            class: 'text-break',
            thStyle: { minWidth: '21rem' },
          }, {
            key: 'action',
            label: 'Action',
          }, 
        ]
        if (this.userRole <= 1) {
          tableFields.splice(16, 0, {
            key: 'tx_power_dbm',
            label: 'Tx Power',
            unit: 'dBm',
            sortable: true,
            thStyle: { minWidth: '6rem' },
          });
          tableFields.splice(17, 0, {
            key: 'last_tx_power_at',
            label: 'Last Tx Power At',
            unit: utcOffset,
            sortable: true,
            thStyle: { minWidth: '10rem' },
          });
          tableFields.splice(19, 0,  {
            key: 'latest_update',
            label: 'Latest Update',
            unit: utcOffset,
            thStyle: { minWidth: '12rem' },
            sortable: true,
          });

        }
        return tableFields
      }
    },
    methods: {
      format, getFleetBrandIcon, highlightString,
      getClassificationLabel(classification) {
        const selectedClass = fleetClassificationOptions.find((item) => item.value == classification)
        return selectedClass ? selectedClass.label : '-'
      }
    },
  };
</script>