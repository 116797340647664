<template>
  <div class="d-flex w-100 gap-1">
    <b-input-group class="mb-md-0 mb-3">
      <template #prepend>
        <b-input-group-text
          class="prepend-outline-secondary py-0 px-2"
          style="background-color: white; border-radius: 0;"
        >
          Auto Fill
        </b-input-group-text>
      </template>

      <!-- Form Select -->
      <Multiselect
        ref="multiselect"
        v-model="localValue" 
        class="form-control py-1 bg-white"
        placeholder="Select"
        :can-deselect="false"
        :close-on-select="true"
        :searchable="false"
        :create-option="false"
        :can-clear="false"
        :options="options"
        @click="toggleSelect"
        @close="isOpen = false"
      >
        <!-- Caret  -->
        <template #caret>
          <span class="custom-caret">
            <i class="bx bx-chevron-down my-auto fs-4" />
          </span>
        </template>
      </Multiselect>
    </b-input-group>
    <div class="bg-white">
      <b-button
        variant="outline-danger"
        class="py-1"
        style="border-radius: 0;;" 
        @click="localValue = ''"
      >
        Clear
      </b-button>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"

export default {
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      localValue: '',
      isOpen: false,
    }
  },        
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.localValue = this.modelValue
      }
    },
    localValue(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  methods: {
    // Fix multiselect does not close on click like regular dropdown
    toggleSelect() {
      if(this.isOpen) {
        this.$refs.multiselect.close()
      } else {
        this.isOpen = true
        this.$refs.multiselect.open()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.multiselect {
  border-radius: 0px;
  border-width: 0px;
  border: 1px solid #CED4DA;
}
</style>