<template>
  <div>
    <div
      v-if="!isEdit"
      style="border: 1px solid #CED4DA"
    >
      <div
        class="fs-15 p-2"
        style="border-bottom: 1px solid #CED4DA"
      >
        <div class="d-flex justify-content-between">
          <div class="w-100 my-auto">
            {{ fleetModel.name }}
          </div>
          <div
            v-if="!isDelete"
            class="d-flex gap-1"
          >
            <b-button
              variant="soft-info"
              @click="isEdit = true"
            >
              <i class="ri-edit-2-line" />
            </b-button>
            <b-button
              variant="soft-danger" 
              @click="isDelete = true"
            >
              <i class="ri-delete-bin-line" />
            </b-button>
          </div>
          <!-- Delete Confirmation -->
          <div
            v-else
            class="d-flex gap-1"
          >
            <b-button
              variant="primary"
              class="d-flex ms-2"
              @click="doDelete"
            >
              <i class="ri-check-line my-auto" />
            </b-button>
            <b-button
              variant="outline-primary"
              class="d-flex ms-2"
              @click="isDelete = false"
            >
              <i class="ri-close-line my-auto" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="p-2">
        <FleetModelData
          label="Brand"
        >
          <template #value>
            <div class="d-flex text-center">
              <img
                :src="getFleetBrandIcon(fleetModel.brand)"
                alt=""
                style="max-height: 20px; max-width: 28px;"
              > 
              <span class="ps-2">
                {{ fleetModel.brand ?? '-' }}
              </span>
            </div>
          </template>
        </FleetModelData>
        <FleetModelData
          label="Series"
          :value="fleetModel.series"
        />
        <FleetModelData
          label="Fleet Type"
          :value="fleetModel.fleet_type"
        />
        <FleetModelData
          label="Cargo Type"
          :value="fleetModel.cargo_type"
        >
          <template #value>
            <div class="text-capitalize">
              {{ fleetModel.cargo_type }}
            </div>
          </template>
        </FleetModelData>
        
        <FleetModelData
          :value="fleetModel.payload_capacity"
        >
          <template #label>
            <b>Payload Capacity</b> (Daya Angkut) <b>- kg</b>
          </template>
        </FleetModelData>
        <FleetModelData
          label="Classification"
        >
          <template #value>
            <div class="text-capitalize">
              {{ getClassificationLabel(fleetModel.classification) }}
            </div>
          </template>
        </FleetModelData>
        <FleetModelData
          :value="fleetModel.engine_displacement"
        >
          <template #label>
            <b>Engine Displacement</b> (Isi Silinder) <b>- cc</b>
          </template>
        </FleetModelData>
        <FleetModelData
          label="Fuel Tank Capacity - L"
          :value="fleetModel.fuel_capacity"
          type="number"
        />
        <FleetModelData
          label="Fuel Type"
        >
          <template #value>
            <div class="text-capitalize">
              {{ fleetModel.fuel_type }}
            </div>
          </template>
        </FleetModelData>
        <FleetModelData
          label="CAN Bus"
          :value="fleetModel.can_bus_available ? 'Available' : 'Unavailable'"
        />
        <FleetModelData
          v-if="fleetModel.can_bus_available != 1"
          label="Fuel Ratio - km/L"
          :value="fleetModel.fuel_ratio"
        />
        <FleetModelData
          label="Carbon Emission Coefficient - kgCO2/L"
          :value="fleetModel.emission_coefficient"
        />
      </div>
    </div>
    <!-- Edit -->
    <b-form
      v-else
      class="bg-light"
      @submit.prevent="doSubmit"
    >
      <div style="border: 1px solid #CED4DA">
        <div
          class="d-flex p-1 bg-light"
          style="border-bottom: 1px solid #CED4DA;"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            class="bg-white"
            type="text"
            placeholder="Type model name"
          />
          <b-button
            type="submit"
            variant="success"
            class="d-flex ms-2"
          >
            <i class="bx bx-save my-auto" />
          </b-button>
          <b-button
            variant="primary"
            class="d-flex ms-2"
            @click="resetForm"
          >
            <i class="bx bx-x my-auto" />
          </b-button>
        </div>
        <div class="p-2">
          <FleetFormInput
            v-model="form.brand"
            background-variant="white"
            label="Brand"
            type="select"
            has-icon
            :options="fleetBrandOptions"
          />
          <FleetFormInput
            v-model="form.series"
            background-variant="white"
            label="Series"
          />
          <FleetFormInput
            v-model="form.fleet_type"
            background-variant="white"
            label="Fleet Type"
            type="select"
            :options="fleetTypeOptions"
          />
          <FleetFormInput
            v-model="form.cargo_type"
            background-variant="white"
            label="Cargo Type"
            type="select"
            :options="fleetCargoTypeOptions"
          />
          <FleetFormInput
            v-model="form.payload_capacity"
            background-variant="white"
            type="number"
          >
            <template #label>
              <b>Payload Capacity</b> (Daya Angkut) <b>- kg</b>
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.classification"
            background-variant="white"
            label="Classification"
            type="select"
            :options="fleetClassificationOptions"
          />
          <FleetFormInput
            v-model="form.engine_displacement"
            background-variant="white"
            type="number"
          >
            <template #label>
              <b>Engine Displacement</b> (Isi Silinder) <b>- cc</b>
            </template>
          </FleetFormInput>
          <FleetFormInput
            v-model="form.fuel_capacity"
            background-variant="white"
            label="Fuel Tank Capacity - L"
            type="number"
          />
          <FleetFormInput
            v-model="form.fuel_type"
            background-variant="white"
            label="Fuel Type"
            type="select"
            :options="fleetFuelTypeOptions"
          />
          <FleetFormInput
            v-model="form.can_bus_available"
            background-variant="white"
            label="CAN Bus"
            type="select"
            :options="fleetCanBusOptions"
          />

          <!-- Fuel Ratio -->
          <FleetFormInput
            v-if="form.can_bus_available == 0"
            v-model="form.fuel_ratio"
            background-variant="white"
            label="Fuel Ratio - km/L"
            type="number"
            :step="0.01"
          />

          <FleetFormInput
            v-model="form.emission_coefficient"
            background-variant="white"
            label="Carbon Emission Coefficient - kgCO2/L"
            type="number"
            :step="0.01"
          />
        </div>
      </div>
    </b-form>
  </div>
</template>
  
<script>
import FleetModelData from '@/components/fleet/FleetModelData.vue';
import FleetFormInput from '@/components/fleet/FleetFormInput.vue';
import { fleetCanBusOptions, fleetTypeOptions, fleetBrandOptions, fleetCargoTypeOptions, fleetClassificationOptions, fleetFuelTypeOptions } from '@/utils/constant';
import { getFleetBrandIcon } from '@/utils/helper';

import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
    FleetFormInput,
    FleetModelData
  },
  props: {
    fleetModel: {
      type: Object,
      default() {
        return {}
      }
    },
    reset: {
      type: Number,
      default: 0
    },
  },
  setup () {
    const toast = useToast();
    return {
      toast,
    }
  },
  data() {
    return {
      isEdit: false,
      isDelete: false,
      form: {
        name: '',
        brand: '',
        series: '',
        fleet_type: '',
        cargo_type: '',
        payload_capacity: '',
        classification: '',
        engine_displacement: '',
        fuel_capacity: '',
        fuel_type: '',
        can_bus_available: '',
        fuel_ratio: '',
        emission_coefficient: '',
      },
      fleetCanBusOptions,
      fleetTypeOptions, 
      fleetBrandOptions,
      fleetCargoTypeOptions,
      fleetClassificationOptions,
      fleetFuelTypeOptions
    }
  },
  computed: {
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
  },
  watch: {
    fleetModel: {
      deep: true,
      immediate: true,
      handler() {
        this.form = { ...this.fleetModel }
      }
    },
    'form.fuel_type' : {
      immediate: false,
      handler(val) {
        if(val == 'diesel') {
          this.form.emission_coefficient = 2.70
        } else if(val == 'biodiesel') {
          this.form.emission_coefficient = 2.50
        } else if(val == 'motor gasoline') {
          this.form.emission_coefficient = 2.32
        }
      }
    },
    reset() {
      this.resetForm()
    }
  },
  methods: {
    getFleetBrandIcon,
    async doSubmit() {
      try {
        await this.$api.updateOrganizationFleetModel(this.selectedOrganization, this.fleetModel.id, this.form);
        this.isEdit = false
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Saved Successfully',
            icon: 'ri-check-double-line'
          }
        }
        this.toast(toastContent);
        this.$emit('update')
      } catch (error) {
        console.log(error);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
    async doDelete() {
      try {
        await this.$api.deleteOrganizationFleetModel(this.selectedOrganization, this.fleetModel.id);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Deleted Successfully',
            icon: 'ri-check-double-line'
          }
        }
        this.toast(toastContent);
        this.$emit('update')
      } catch (error) {
        console.log(error);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
    resetForm() {
      this.form = { ...this.fleetModel }
      this.isEdit = false 
      this.isDelete = false
    },
    getClassificationLabel(classification) {
      const selectedClass = fleetClassificationOptions.find((item) => item.value == classification)
      return selectedClass ? selectedClass.label : '-'
    },
  },
}
</script>